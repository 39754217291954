(function(window, document, undefined){

	window.addEventListener('DOMContentLoaded', (event) => {
		init();
	});

	async function init(){
		try{
			// Get the DIV element
			var element = document.getElementById('shc-container');
			var domain =  element.getAttribute('data-domain');
			var agency_data = false;

			await getAgencyData(domain).then(data => {
				try{
					if(typeof data === 'object'){
						agency_data = data;
					}else{
						throw Error(data);
					}
				}catch(error){
					console.error('getAgencyDataCall', error);
				}
			});

			// Setup variables from API response
			var domain = agency_data['dom'];
			var key = agency_data["api_key"];
			var require_phone = (agency_data['require_phone'] == 1);
			var require_gdpr = (agency_data['require_gdpr'] == 1);
			var gdpr_url = agency_data['gdpr_url'];
			var cta_button_color = agency_data['cta_button_color'];
			var cta_text_color = agency_data['cta_text_color'];
			var cta_text = agency_data['cta_text'];

			// Update global vars
			use_recaptcha = agency_data['use_recaptcha'];
			recaptcha_key = agency_data['recaptcha_key'];

			if(!domain){
				domain = 'hike';
			}

			// Create CSS Stylesheet //
			var head = document.head;
			var css = document.createElement("link");
			css.type = "text/css";
			css.rel = "stylesheet";
			css.href = 'https://' + domain + '/css/shc-custom.min.css';
			head.appendChild(css);
			// Create CSS Stylesheet //

			// Create the initial form //
			var f = document.createElement("form");
			f.setAttribute('method',"post");
			f.setAttribute('id',"shc-form");
			//Force https here
			f.setAttribute('action', 'https://' + domain + '/healthcheck/api/register'); // TEST ON LOCAL
			f.setAttribute('onSubmit', 'handleFormSubmit(event)');

			// API KEY //
			let api_key_input = document.createElement("input");
			api_key_input.setAttribute('type',"hidden");
			api_key_input.setAttribute('name',"key");
			api_key_input.setAttribute('required',"required");
			api_key_input.setAttribute('id',"shc-key-input");
			api_key_input.value = key;
			f.appendChild(api_key_input);

			// WEBSITE URL //
			var u = document.createElement("input");
			u.setAttribute('type',"url");
			u.setAttribute('placeholder', 'URL (include https://)');
			u.setAttribute('name',"website_url");
			u.setAttribute('required',"required");
			u.setAttribute('id',"shc-url-input");
			f.appendChild(u);

			// EMAIL //
			var e = document.createElement("input");
			e.setAttribute('type',"email");
			e.setAttribute('placeholder', ' Email Address');
			e.setAttribute('name',"email");
			e.setAttribute('required',"required");
			e.setAttribute('id',"shc-email-input");
			f.appendChild(e);

			// NAME //
			var n = document.createElement("input");
			n.setAttribute('type',"text");
			n.setAttribute('placeholder', ' Full Name');
			n.setAttribute('name',"name");
			n.setAttribute('required',"required");
			n.setAttribute('id',"shc-name-input");
			f.appendChild(n);

			// PHONE //
			if(require_phone){
				var p = document.createElement("input");
				p.setAttribute('type',"tel");
				p.setAttribute('placeholder', ' Phone Number');
				p.setAttribute('name',"phone");
				p.setAttribute('required',"required");
				p.setAttribute('id',"shc-phone-input");
				f.appendChild(p);
			}

			// GDPR //
			if(require_gdpr == 1){
				var para = document.createElement("p");
				para.setAttribute('id',"shc-gdpr-container");

				var g = document.createElement("input");
				g.setAttribute('type',"checkbox");
				g.setAttribute('value', '1');
				g.setAttribute('checked', 'checked');
				g.setAttribute('name',"gdpr");
				g.setAttribute('id',"shc-gdpr-input");

				if(gdpr_url){
					var gdpr_text = document.createTextNode("You can contact me regarding my website audit, in compliance with our ");
					var gdpr_anchor = document.createElement('a');
					var anchor_text = document.createTextNode('GDPR Policy');

					gdpr_anchor.setAttribute('href', gdpr_url);
					gdpr_anchor.appendChild(anchor_text);
					gdpr_anchor.setAttribute('target', '_blank');

					para.appendChild(g);
					para.appendChild(gdpr_text);
					para.appendChild(gdpr_anchor);
				}else{
					var gdpr_text = document.createTextNode("You can contact me regarding my website audit.");
					para.appendChild(g);
					para.appendChild(gdpr_text);
				}

				f.appendChild(para);
			}

			// SUBMIT BUTTON //

			if(!cta_button_color){
				cta_button_color = '#000000';
			}

			if(!cta_text_color){
				cta_text_color = '#FFFFFF';
			}

			if(!cta_text){
				cta_text = 'Run My Free Report';
			}

			if(use_recaptcha == 1){
				// Google reCAPTCHA v3 //

				// Add script tag to head
				var recaptcha_script = document.createElement('script');
				recaptcha_script.src = 'https://www.google.com/recaptcha/api.js?render='+recaptcha_key;
				recaptcha_script.setAttribute('async', '');
				recaptcha_script.setAttribute('defer', '');
				head.appendChild(recaptcha_script);

			}

			var s = document.createElement("input");
			s.setAttribute('type', "submit");
			s.setAttribute('value', cta_text);
			s.setAttribute('id', 'shc-submit-button');
			s.setAttribute('style',"background-color:" + cta_button_color + '; color:' + cta_text_color + ';');
			f.appendChild(s);

			element.appendChild(f);

			// Validation for URL //
			urlError = document.createElement('div');
			urlError.setAttribute('class', 'error-alert');
			urlError.setAttribute('id', 'url-error-box');
			urlError.innerHTML = '<strong>Error!</strong> Please include ‘https://’ at the beginning of your URL (e.g: https://yourwebsite.com).';

			urlInput = document.getElementById("shc-url-input");
			urlInput.before(urlError);

			urlInput.addEventListener("change", checkURL);

			// Validation for Email //
			emailError = document.createElement('div');
			emailError.setAttribute('class', 'error-alert');
			emailError.setAttribute('id', 'email-error-box');
			emailError.innerHTML = '<strong>Error!</strong> Please make sure that you use a valid Email.';

			emailInput = document.getElementById("shc-email-input");
			emailInput.before(emailError);

			emailInput.addEventListener("change", checkEmail);


		}catch(error){
			console.error('init', error);
		}
	}

})(window, document, undefined);

async function getAgencyData(url = '', data = {}) {
	try{
		const response = await fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data)
		});
		return response.json();
	}catch(error){
		console.error('getAgencyDataFunc', error);
	}

}

var recaptcha_key = null;
var use_recaptcha = false;

function handleFormSubmit(event){
	try{
		if(use_recaptcha == 1){
			// Check for and remove any existing token
			var existingInput = document.getElementById('recaptcha-token')
			if(existingInput){
				existingInput.remove();
			}

			// Add hidden form input for reCAPTCHA token
			var token = document.createElement("input");
			token.setAttribute('type', 'hidden');
			token.setAttribute('name', 'recaptcha-token');
			token.setAttribute('id', 'recaptcha-token');
			token.setAttribute('required', 'required');
			document.getElementById('shc-form').appendChild(token);
			// Generate the token
			event.preventDefault()
			grecaptcha.execute(recaptcha_key).then(function(token) {
				document.getElementById('recaptcha-token').value = token;
				document.getElementById('shc-form').submit();
				return true;
			});
		}
	}catch(error){
		console.error('handleFormSubmit', error);
	}

}

function checkURL() {
	var url = document.getElementById("shc-url-input");
	var error = document.getElementById("url-error-box");
	if(validURL(url.value)){
		error.style.display = 'none';
	}else{
		error.style.display = 'block';
	}
}

function checkEmail() {
	var email = document.getElementById("shc-email-input");
	var error = document.getElementById("email-error-box");
	if(validEmail(email.value)){
		error.style.display = 'none';
	}else{
		error.style.display = 'block';
	}
}

function validURL(url) {
	regexp =  /^(?:(?:https?|ftp):\/\/)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(url)){
      return true;
    }else{
      return false;
    }
}

function validEmail(email) {
    const regex_pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (regex_pattern.test(email)) {
        return true;
    } else {
        return false;
    }
}
